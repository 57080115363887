:root {
  --app-height: 100%;
}

label.required:after {
  content: '*';
  color: red;
  margin-left: 0.5rem;
}

body > div#root {
  height: 100vh;
  height: var(--app-height);
}

@media print {
  .page-break {
    page-break-before: always;
  }
  .avoid-page-break {
    page-break-inside: avoid;
  }
}

table.table thead {
  user-select: none;
}

.image-capture {
  height: 100vh;
  height: var(--app-height);
}

.capture-normal {
  background-color: #000000;
  transition: background-color 250ms;
}

.capture-success {
  background-color: #333333;
}

.modal-fullscreen {
  padding: 0 !important;
}

.modal-fullscreen .modal-dialog {
  max-width: 100% !important;
  min-height: 100vh !important;
  margin: 0 !important;
}

.modal-fullscreen .modal-content {
  min-height: 100vh !important;
  border: 0 !important;
  border-radius: 0 !important;
}
